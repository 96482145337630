import { styled } from '@mui/system'
import Hero from '@bluheadless/ui/src/organisms/hero/hero'
import { Root } from '@bluheadless/ui/src/organisms/hero/hero.styled'
import {
	Title as HeroBannerTitle,
	Subtitle as HeroBannerSubtitle,
	Description as HeroBannerDescription,
	CtaContainer as HeroBannerCtaContainer,
	Link as HeroBannerLink,
	Button as HeroBannerButton,
} from '@bluheadless/ui/src/organisms/hero/hero-banner.styled'

const shouldForwardProp = (prop) => {
	return !['colorHex'].includes(prop)
}
const Title = styled(HeroBannerTitle)``
const Subtitle = styled(HeroBannerSubtitle)``
const Description = styled(HeroBannerDescription)``
const CtaContainer = styled(HeroBannerCtaContainer)``
const Link = styled(HeroBannerLink)``
const Button = styled(HeroBannerButton, { shouldForwardProp })``

const HeroStyled = styled(Hero, Root)``

const Container = styled('div')`
	display: flex;
	flex-direction: column;
	pointer-events: auto;
	position: sticky;
	top: var(--header-sticky-top, 0px);
	left: 0;
	padding: ${({ theme }) => theme.spacing(5, 1)};
	height: fit-content;
	${({ theme }) => theme.breakpoints.up('md')} {
		padding: ${({ theme }) => theme.spacing(5)};
	}
`

export { Container, Title, Subtitle, Description, CtaContainer, Link, Button, HeroStyled }
